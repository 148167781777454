import React, { useState, Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import data from "../data.json";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";
import resume from "../assets/atinachang.pdf";
import Seo from "../components/seo";

import { FaReact, FaWordpress, FaPhp, FaShopify, FaNode } from "react-icons/fa";
import { GrGatsbyjs, GrGraphQl, GrHeroku } from "react-icons/gr";
import {
	DiCss3,
	DiFirebase,
	DiMongodb,
	DiJavascript1,
	DiBootstrap,
} from "react-icons/di";
import {
	SiRubyonrails,
	SiPostgresql,
	SiExpress,
	SiSanity,
} from "react-icons/si";
import { TbBrandNextjs } from "react-icons/tb";
import "swiper/css";
import "swiper/css/navigation";

const iconArray = {
	gatsby: <GrGatsbyjs />,
	react: <FaReact />,
	wordpress: <FaWordpress />,
	graphql: <GrGraphQl />,
	php: <FaPhp />,
	shopify: <FaShopify />,
	css: <DiCss3 />,
	firebase: <DiFirebase />,
	mongodb: <DiMongodb />,
	heroku: <GrHeroku />,
	ruby: <SiRubyonrails />,
	postgres: <SiPostgresql />,
	javascript: <DiJavascript1 />,
	node: <FaNode />,
	express: <SiExpress />,
	bootstrap: <DiBootstrap />,
	nextjs: <TbBrandNextjs />,
	"sanity.io": <SiSanity />,
};

const IndexPage = ({
	data: {
		allFile: { nodes },
	},
}) => {
	const [toggle, setToggle] = useState("Work");

	const PortfolioToggle = (e) => {
		const { textContent } = e.target;

		if (textContent === "Work") {
			setToggle(textContent);
		}
		if (textContent === "Projects") {
			setToggle(textContent);
		}
	};

	const getClientLogos = (client) => {
		const getLogoImages = nodes.filter(
			(item) => item.relativeDirectory === "logos",
		);

		const getLogo = getLogoImages.filter((item) => {
			return item.name === client;
		});
		return (
			<GatsbyImage
				image={getLogo[0].childImageSharp.gatsbyImageData}
				alt=""
				width={200}
				height={20}
			/>
		);
	};

	const getImage = (array) => {
		if (array.length !== 0) {
			return array.map(({ childImageSharp }, i) => {
				return (
					<SwiperSlide key={i}>
						<GatsbyImage image={childImageSharp.gatsbyImageData} alt="" />
					</SwiperSlide>
				);
			});
		}
		return (
			<SwiperSlide>
				<GatsbyImage
					image={array[0].childImageSharp.gatsbyImageData}
					alt={array[0].name}
				/>
			</SwiperSlide>
		);
	};

	const getClientImages = (client) => {
		const getImages = nodes.filter(
			(item) => item.relativeDirectory !== "logos",
		);

		if (client === "Somewherelse") {
			const somewherelse = getImages.filter(
				(item) => item.relativeDirectory === "se",
			);
			return getImage(somewherelse);
		}
		if (client === "Pure Sunfarms") {
			const puresunfarms = getImages.filter(
				(item) => item.relativeDirectory === "psf",
			);
			return getImage(puresunfarms);
		}
		if (client === "Big Builds") {
			const bigbuilds = getImages.filter(
				(item) => item.relativeDirectory === "bb",
			);
			return getImage(bigbuilds);
		}
		if (client === "Ellis Don") {
			const ed = getImages.filter(
				(item) => item.relativeDirectory === "ellisdon",
			);
			return getImage(ed);
		}
		if (client === "Son of Rand") {
			const sor = getImages.filter((item) => item.relativeDirectory === "sor");
			return getImage(sor);
		}
	};

	const getProjects = (name) => {
		const getArr = nodes.filter((item) => item.relativeDirectory !== "logos");

		if (name === "wecreate.to") {
			const project = getArr.filter((item) => item.name === "wecreate");
			return getImage(project);
		}
		if (name === "Second Life") {
			const project = getArr.filter((item) => item.name === "second life");
			return getImage(project);
		}

		if (name === "Alcohol Tracking App") {
			const project = getArr.filter((item) => item.name === "trackerapp");
			return getImage(project);
		}
		return;
	};

	let currentSelection = [];

	data.forEach((data) => {
		if (toggle === "Work" && data.type === "work") {
			currentSelection.push(data);
		}
		if (toggle === "Projects" && data.type === "projects") {
			currentSelection.push(data);
		}
	});

	return (
		<Fragment>
			<Seo title="Developer" />
			<main>
				<div className="container" style={{ justifyContent: "space-between" }}>
					<div className="intro">
						<h1>Who is asey?</h1>
						<div className="mobile__image">
							<StaticImage
								src="../images/memoji.png"
								alt=""
								width={200}
								height={200}
							/>
						</div>
						<p>
							Atina a Software Developer based in Toronto, with a passion for
							front end experiences, and an interest in designer-developer
							collaboration.
						</p>
						<p>
							When she's not writing code, Atina (aka asey) DJ's across Toronto
							and host's a radio show, every first and third Thursday of the
							month, on a community online radio station called{" "}
							<Link to="https://iso.fm" target="_blank" rel="noreferrer">
								ISO Radio
							</Link>
							.
						</p>
						<p>
							You can check out archives of her show <Link to="/dj">here</Link>
						</p>
						<p>
							Take a look at her resume{" "}
							<Link to={resume} target="_blank" rel="noreferrer">
								here
							</Link>
						</p>
						<p></p>
						<p>Toggle her work below &#128071;</p>
					</div>
					<div className="desktop__image">
						<StaticImage
							src="../images/memoji.png"
							alt=""
							width={200}
							height={200}
						/>
					</div>
				</div>

				<div className="title__separator">
					<button
						className="h2"
						onClick={(e) => PortfolioToggle(e)}
						onKeyDown={(e) => PortfolioToggle(e)}
						style={{
							textDecoration: toggle === "Work" ? "underline" : "none",
						}}>
						Work
					</button>
					<button
						className="h2"
						onClick={(e) => PortfolioToggle(e)}
						onKeyDown={(e) => PortfolioToggle(e)}
						style={{
							textDecoration: toggle === "Projects" ? "underline" : "none",
						}}>
						Projects
					</button>
				</div>

				{currentSelection.map(
					({ name, description, details, tech, website, type, other }, i) => {
						return (
							<div className={`container__experience`} key={i}>
								<div className="container__item">
									<div className="container__content">
										<Link to={website} target="_blank" rel="noreferrer">
											{type === "projects" ? (
												<h3>{name}</h3>
											) : (
												getClientLogos(name)
											)}
										</Link>
										<p>{description}</p>
										{type === "client" ? <h4>Work + Learnings</h4> : null}
										<ul>
											{details.map((item, i) => (
												<li key={i}>{item}</li>
											))}
											{other !== undefined ? <li>{other[0].title}</li> : null}
										</ul>
										<ul className="tech__icons">
											{tech.map(({ name }, i) => (
												<li key={i}>{iconArray[name]}</li>
											))}
										</ul>
									</div>
									<div className="container__images">
										<Swiper
											navigation={true}
											modules={[Navigation]}
											className="mySwiper">
											{type === "work"
												? getClientImages(name)
												: getProjects(name)}
										</Swiper>
									</div>
								</div>
							</div>
						);
					},
				)}

				<div className="container">
					<p>
						Links:{" "}
						<Link
							to="https://twitter.com/whoisasey"
							target="_blank"
							rel="noreferrer">
							Twitter
						</Link>
						,{" "}
						<Link
							to="https://github.com/whoisasey"
							target="_blank"
							rel="noreferrer">
							Github
						</Link>
						,{" "}
						<Link
							to="https://www.linkedin.com/in/atinachang/"
							target="_blank"
							rel="noreferrer">
							LinkedIn
						</Link>
					</p>
				</div>
			</main>
		</Fragment>
	);
};

export const query = graphql`
	query imagesQuery {
		allFile(filter: { relativeDirectory: { ne: "" } }) {
			nodes {
				name
				relativeDirectory
				extension
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	}
`;

export default IndexPage;
